import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { VSection } from "src/components/common"
import styled from "styled-components"

const DeepwatersManifesto = () => {
  const { deepwaters_manifesto } = useStaticQuery(QUERY)

  return (
    <StyledDeepwatersManifesto>
      <VSection
        {...deepwaters_manifesto}
        hatIcon={{ desktop: deepwaters_manifesto.hatIcon }}
        subtitleAs="pSmall"
      />
    </StyledDeepwatersManifesto>
  )
}

const StyledDeepwatersManifesto = styled.div`
  --section-align: center;
  --tg-text-align: center;
  --tg-max-width: 550px;
  position: relative;
  z-index: 2;
  .hatIconWrap {
    justify-content: center !important;
  }
`

export const QUERY = graphql`
  {
    deepwaters_manifesto: contentfulSection(
      sectionId: { eq: "deepwaters_manifesto" }
    ) {
      ...Section
    }
  }
`

export default DeepwatersManifesto
