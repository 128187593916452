import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import {
  Card2,
  Grid,
  Layout,
  Seo,
  ValueProp,
  VSection,
  Link,
} from "components/common"
import Footer from "components/navigation/Footer"

import { useContentfulSection, useMeta, useSortProducts } from "utils"
import Nav from "components/navigation/Nav"
import MobileNav from "src/components/navigation/mobile/MobileNav"
import BottomNewsletterCta from "src/components/common/shared/BottomNewsletterCta"
import DeepwatersManifesto from "src/sections/product/DeepwatersManifesto"
import classNames from "classnames"

const ProductPage = ({ data: { product, bottom_cta, products } }) => {
  const meta = useMeta(product)
  const { product_hero } = useContentfulSection(product.sections)

  const sortedProblems = useSortProducts(products)

  const renderGrid = () => (
    <Grid
      list={sortedProblems}
      component={({ slug, shortTitle, shortSubtitle, to, disabled }) => (
        <Link to={to} className={classNames("productCard", { disabled })}>
          <Card2>
            <ValueProp
              title={shortTitle}
              subtitle={slug ? shortSubtitle : "Coming soon."}
            />
          </Card2>
        </Link>
      )}
    />
  )

  return (
    <Layout>
      <Seo {...meta} />
      <Nav />
      <MobileNav />

      <Wrap>
        <VSection
          className="productHero"
          titleAs="h1"
          align="left"
          {...product_hero}
          grid={renderGrid}
        />

        <DeepwatersManifesto />

        <BottomNewsletterCta {...bottom_cta} />
      </Wrap>
      <Footer />
    </Layout>
  )
}

const Wrap = styled.div`
  .productHero {
    --section-padding-top: 200px;
    --section-mobile-padding-top: 20vh;
  }

  .v_gridContainer {
    --grid-gap: var(--sp-24);
    --grid-mobile-gap: var(--sp-24);
    --vp-grid-gap: 0;
  }

  .productCard {
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .card2 {
      width: 100%;
      height: 100%;
    }
  }
`

export const query = graphql`
  {
    product: contentfulPage(pageId: { eq: "product" }) {
      ...Page
    }
    bottom_cta: contentfulSection(sectionId: { eq: "bottom_newsletter_cta" }) {
      ...Section
    }
    products: allContentfulProduct {
      edges {
        node {
          ...Product
        }
      }
    }
  }
`

export default ProductPage
